<template>
    <div class="bg-color rounded-5 p-5 d-flex flex-column gap-4">
        <div
            class="rounded-4 bg-white p-4 d-flex flex-column justify-content-center align-items-center gap-3"
            style="min-height: 20rem"
        >
            <div
                class="d-flex flex-column justify-content-center align-items-center gap-2 w-75"
                @dragenter.prevent
                @dragleave.prevent
                @dragover.prevent
                @drop.prevent="uploadFile($event.dataTransfer.files[0], '3')"
            >
                <img src="@/assets/px/drag-and-drop/upload-file-icon.svg" alt="" class="img" style="width: 100px" />
                <span style="color: #566371; font-weight: 600; font-size: 16px"> Drag and Drop files here </span>
            </div>
            <div class="my-4 d-flex justify-content-center align-items-center">
                <div class="or-bg"></div>
                <div class="or-circle">OR</div>
            </div>
            <div>
                <input type="file" ref="upload" @change="uploadFile($event.target.files[0])" accept=".svg" hidden />
                <button type="button" class="upload-btn" @click="chooseFile">Upload</button>
            </div>
        </div>

        <div class="rounded-4 bg-white p-4" style="min-height: 25rem">
            <div class="d-flex align-items-center mx-4 p-2">
                <div class="flex-1" style="color: #566371; font-weight: 600; font-size: 1.8rem">List of Icons</div>
                <div class="d-flex gap-3">
                    <button
                        v-if="showDeleteBtn"
                        type="button"
                        class="delete-btn d-flex align-items-center gap-1"
                        @click="showDeleteSvgModal = true"
                    >
                        <img src="@/assets/px/drag-and-drop/delete-icon.svg" alt="" class="img" style="width: 15px" />
                        Delete
                    </button>
                    <span class="d-flex search-svg-icon px-2">
                        <input v-model="searchedSvg" type="text" placeholder="Find icon here" @input="handleSearch" />
                        <img
                            src="@/assets/px/drag-and-drop/search-icon.svg"
                            alt=""
                            class="img mx-2"
                            style="width: 20px"
                        />
                    </span>
                </div>
            </div>
            <div
                v-if="!loading && svgList.length"
                class="d-flex flex-wrap m-4 p-2 gap-3 overflow-auto"
                style="max-height: 30rem; margin-top: 2rem !important"
            >
                <div
                    v-for="(svg, index) in svgList.slice().reverse()"
                    :key="index"
                    :class="(svg.id === selectedSvg.id ? 'selected-img ' : '') + 'img-container'"
                    @click="selectSvg(svg)"
                >
                    <ejs-tooltip
                        target="#target"
                        :content="svg.title"
                        class="tooltipcontainer"
                        cssClass="customtooltip"
                    >
                        <img id="target" :src="svg.imageUrl" alt="" class="img" style="width: 50px" />
                    </ejs-tooltip>
                </div>
            </div>
            <div v-else-if="!loading && searchedSvg !== ''" class="w-100">
                <div class="d-flex justify-content-center align-items-center py-5" style="min-height: 200px">
                    <h4>No SVG available</h4>
                </div>
            </div>
            <div v-else-if="!loading && svgList.length === 0" class="w-100">
                <div class="d-flex justify-content-center align-items-center py-5" style="min-height: 200px">
                    <h4>No SVG Added</h4>
                </div>
            </div>
            <div v-else class="w-100">
                <div class="d-flex justify-content-center align-items-center py-5" style="min-height: 200px">
                    <h4>Fetching information...</h4>
                </div>
            </div>
        </div>

        <AddSvgModal v-if="showAddSvgModal" :fileInfo="fileInfo" @handleSave="addNewSvg" @cancel="resetFile" />
        <DeleteSvgModal v-if="showDeleteSvgModal" @handleDelete="handleDelete" @cancel="resetDelete" />

        <Loading v-if="loading" />
    </div>
</template>

<script>
    import Loading from '@/components/general/loading/loading.vue';
    import AddSvgModal from './add_svg_modal.vue';
    import DeleteSvgModal from './delete_svg_modal.vue';
    import { svgPicker } from '../../util/apiRequests';

    export default {
        name: 'SvgIconsSection',
        components: {
            AddSvgModal,
            DeleteSvgModal,
            Loading,
        },
        data() {
            return {
                loading: true,
                showDeleteBtn: false,
                showAddSvgModal: false,
                showDeleteSvgModal: false,
                searchedSvg: '',
                fileInfo: null,
                selectedSvg: {},
                allSvgList: [],
                svgList: [],
            };
        },
        methods: {
            async getSvgList() {
                try {
                    const endpoint = svgPicker.getSvgList();
                    const res = await this.$api.get(endpoint);
                    this.allSvgList = res.data;
                    this.svgList = res.data;
                    this.searchedSvg = '';
                } catch (err) {
                    this.$toasted.error('Failed to fetch SVG List');
                }
            },
            async addNewSvg(data) {
                try {
                    this.loading = true;
                    const endpoint = svgPicker.addSvg();
                    const res = await this.$api.post(endpoint, data);
                    if (res.data.success) {
                        await this.getSvgList();
                        this.$toasted.success('SVG added successfully');
                    }
                } catch (err) {
                    this.$toasted.error('Failed to add SVG');
                } finally {
                    this.loading = false;
                }
            },
            async handleDelete() {
                try {
                    this.loading = true;
                    const endpoint = svgPicker.deleteSvg(this.selectedSvg?.id);
                    const res = await this.$api.delete(endpoint);
                    if (res.data.success) {
                        await this.getSvgList();
                        this.$toasted.success('SVG deleted successfully');
                    }
                    this.resetDelete();
                } catch (err) {
                    this.$toasted.error('Failed to delete SVG');
                } finally {
                    this.loading = false;
                }
            },
            chooseFile() {
                if (this.$refs.upload != undefined) {
                    this.$refs.upload.click();
                }
            },
            uploadFile(file) {
                if (file) {
                    this.$refs.upload.value = '';
                    this.fileInfo = null;
                    
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];

                    if (fileExtension !== 'svg') {
                        this.$toasted.error('Only SVG files are allowed');
                        return;
                    }

                    if (fileSize / 1024 / 1024 > 10) {
                        this.$toasted.error('Image size should be less than 10MB');
                        this.$refs.upload.value = '';
                        return;
                    }

                    this.fileInfo = file;
                    this.showAddSvgModal = true;
                }
            },
            handleSearch() {
                const searchedText = this.searchedSvg?.toLowerCase();
                this.svgList = this.allSvgList.filter((item) => {
                    return (
                        item.title.toLowerCase().includes(searchedText) ||
                        item.categories.toLowerCase().includes(searchedText)
                    );
                });
            },
            selectSvg(value) {
                if (this.selectedSvg?.id === value.id) {
                    this.selectedSvg = {};
                    this.showDeleteBtn = false;
                    return;
                }
                this.selectedSvg = value;
                this.showDeleteBtn = true;
            },
            resetFile() {
                this.showAddSvgModal = false;
                this.fileInfo = null;
            },
            resetDelete() {
                this.showDeleteSvgModal = false;
                this.showDeleteBtn = false;
                this.selectedSvg = {};
            },
        },
        async created() {
            await this.getSvgList();
            this.loading = false;
        },
    };
</script>

<style scoped>
    .bg-color {
        background-color: rgba(255, 255, 255, 0.9);
    }

    .upload-btn {
        background: #5057c3;
        color: white;
        width: 150px;
        height: 50px;
        font-size: 16px;
        border-radius: 10px;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
    }

    .delete-btn {
        color: #919fad;
        background: white;
        border-radius: 5px;
        border: 1px solid #b1d0ff;
        box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.1);
    }

    .or-circle {
        z-index: 1;
        width: 20px;
        height: 20px;
        padding: 7px;
        font-weight: 600;
        border-radius: 50%;
        position: absolute;
        background: white;
        border: 2px solid #e2e2e2;
    }

    .or-bg {
        width: 800px;
        height: 2px;
        background-color: #e2e2e2;
    }

    .search-svg-icon {
        border-radius: 5px;
        border: 1px solid #b1d0ff;
    }

    .search-svg-icon input {
        box-shadow: none;
    }

    .search-svg-icon input:focus-visible,
    .search-svg-icon input:focus {
        outline: none;
    }

    .search-svg-icon input::placeholder {
        color: #919fad;
        font-size: 14px;
    }

    .img-container {
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.1);
    }

    .selected-img {
        border: 2px dotted #dc3545;
        border-radius: 10px;
    }
</style>
